import { getAPI, postAPI } from '../../utils/api'
import { 
    GET_TITLE,
    GET_province,
    GET_district,
    GET_sub_district,
    GET_OrgType,
    GET_EngineeringCourse,
    GET_STATUS,
    GET_vw_cpd_activity_criteria_and_conditions,
    GET_vw_activity_type,
    GET_elearning_prototype_img,
    GET_SERVICE_TYPE,
    GET_MASTER,
    GET_EVENT_ENGINEER,
    GET_vw_field_activity,
    GET_vw_criteria_activity,
    GET_MEMBER_ORG
} from '../actionTypes'
import { showLoading, hideLoading } from './loading'

export const getTitle = (filter = { limit: 1000, skip: 0 }) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: GET_TITLE.REQUEST })

      const headers = {
        'Content-Type': 'application/json',
        Authorization: null,
      }

      let params = {
        '$limit': filter.limit,
        '$skip': filter.skip,
      }
  
      const data_result = await getAPI({
        url: '/api/mas/getTitle?$sort[id]=1',
        headers: headers,
        params,
      })

      return { type: 'GET_TITLE_SUCCESS', data_info: data_result }

    } catch (err) {
      return { type: 'GET_TITLE_ERROR' }

    } finally {
      dispatch(hideLoading())
    }
}

export const ApiGetProvince = (filter = { limit: 200, skip: 0 }) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: GET_province.REQUEST })

    const headers = {
      Authorization: null,
      'Content-Type': 'application/json',
    }
    let params = {
      '$limit': filter.limit,
      '$skip': filter.skip,
    }

    const data_result = await getAPI({
      url: '/api/mas/get_province?$sort[id]=1',
      headers: headers,
      params,
    })

    return { type: 'ApiGetProvince_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiGetProvince_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiGetDistrict = (filter = { limit: 200, skip: 0 }) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: GET_district.REQUEST })

    const headers = {
      Authorization: null,
      'Content-Type': 'application/json',
    }
    let params = {
      '$limit': filter.limit,
      '$skip': filter.skip,
      'province_id': filter.province_id,
    }

    const data_result = await getAPI({
      url: '/api/mas/get_district?$sort[id]=1',
      headers: headers,
      params,
    })

    return { type: 'ApiGetDistrict_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiGetDistrict_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiGetSub_district = (filter = { limit: 200, skip: 0 }) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: GET_sub_district.REQUEST })

    const headers = {
      Authorization: null,
      'Content-Type': 'application/json',
    }
    let params = {
      '$limit': filter.limit,
      '$skip': filter.skip,
      'district_id': filter.district_id,
    }

    const data_result = await getAPI({
      url: '/api/mas/get_subdistrict?$sort[id]=1',
      headers: headers,
      params,
    })

    return { type: 'ApiGetSub_district_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiGetSub_district_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiGetOrgType = (filter = { limit: 200, skip: 0 }) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: GET_OrgType.REQUEST })

    const headers = {
      Authorization: null,
      'Content-Type': 'application/json',
    }
    let params = {
      '$limit': filter.limit,
      '$skip': filter.skip,
    }

    const data_result = await getAPI({
      url: '/api/mas/getOrgType?$sort[id]=1',
      headers: headers,
      params,
    })

    return { type: 'ApiGetOrgType_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiGetOrgType_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiGetEngineeringCourse = (filter = { limit: 200, skip: 0 }) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: GET_EngineeringCourse.REQUEST })

    const headers = {
      Authorization: null,
      'Content-Type': 'application/json',
    }
    let params = {
      '$limit': filter.limit,
      '$skip': filter.skip,
    }

    const data_result = await getAPI({
      url: '/api/mas/engineering_course?$sort[id]=1',
      headers: headers,
      params,
    })

    return { type: 'ApiGetEngineeringCourse_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiGetEngineeringCourse_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiGetStatus = (filter = { limit: 200, skip: 0 }) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: GET_STATUS.REQUEST })

    const headers = {
      Authorization: null,
      'Content-Type': 'application/json',
    }
    let params = {
      '$limit': filter.limit,
      '$skip': filter.skip,
    }

    if(filter.category_name){
      params = {...params,'category_name': filter.category_name}
    }

    const data_result = await getAPI({
      url: '/api/mas/mas_status?$sort[id]=1',
      headers: headers,
      params,
    })

    return { type: 'ApiGetStatus_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiGetStatus_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiGet_vw_cpd_activity_criteria_and_conditions = (filter = { limit: 200, skip: 0 }) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: GET_vw_cpd_activity_criteria_and_conditions.REQUEST })

    const headers = {
      Authorization: null,
      'Content-Type': 'application/json',
    }
    let params = {
      '$limit': filter.limit,
      '$skip': filter.skip,
    }

    if(filter.activity_type){
      params = {...params,'activity_type_no': filter.activity_type}
    }

    if(filter.status_id){
      params = {...params,'status_id': filter.status_id}
    }

    const data_result = await getAPI({
      url: '/api/mas/vw_cpd_activity_criteria_and_conditions?$sort[id]=1',
      headers: headers,
      params,
    })

    return { type: 'ApiGet_vw_cpd_activity_criteria_and_conditions_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiGet_vw_cpd_activity_criteria_and_conditions_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiGet_vw_activity_type = (filter = { limit: 200, skip: 0 }) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: GET_vw_activity_type.REQUEST })

    const headers = {
      Authorization: null,
      'Content-Type': 'application/json',
    }
    let params = {
      '$limit': filter.limit,
      '$skip': filter.skip,
    }

    const data_result = await getAPI({
      url: '/api/mas/vw_activity_type?$sort[activity_type_no]=1',
      headers: headers,
      params,
    })

    return { type: 'ApiGet_vw_activity_type_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiGet_vw_activity_type_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiGetElearningPrototypeImg = (filter = { limit: 200, skip: 0 }) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: GET_elearning_prototype_img.REQUEST })

    const headers = {
      Authorization: null,
      'Content-Type': 'application/json',
    }
    let params = {
      '$limit': filter.limit,
      '$skip': filter.skip,
    }

    const data_result = await getAPI({
      url: '/api/mas/elearning_prototype_img?$sort[id]=1',
      headers: headers,
      params,
    })

    return { type: 'ApiGetElearningPrototypeImg_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiGetElearningPrototypeImg_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiGET_SERVICE_TYPE = (filter = { limit: 200, skip: 0 }) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: GET_SERVICE_TYPE.REQUEST })

    const headers = {
      Authorization: null,
      'Content-Type': 'application/json',
    }
    let params = {
      '$limit': filter.limit,
      '$skip': filter.skip,
      'status_id' : 1
    }

    const data_result = await getAPI({
      url: '/api/mas/service_type?$sort[id]=1',
      headers: headers,
      params,
    })

    return { type: 'ApiGET_SERVICE_TYPE_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiGET_SERVICE_TYPE_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const Api_GETCpdConsent = (id) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: GET_MASTER.REQUEST })
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    const data_result = await getAPI({
      url: `/api/mas/cpd_consent/${id}`,
      headers: headers,
    })

    return { type: 'Api_GETCpdConsent_SUCCESS', data_info: data_result }

  } catch (err) {

    return { type: 'Api_GETCpdConsent_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiGET_EVENT_ENGINEER = (filter = { limit: 200, skip: 0 }) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: GET_EVENT_ENGINEER.REQUEST })

    const headers = {
      Authorization: null,
      'Content-Type': 'application/json',
    }
    let params = {
      '$limit': filter.limit,
      '$skip': filter.skip
    }

    const data_result = await getAPI({
      url: '/api/mas/event_engineering?$sort[id]=1',
      headers: headers,
      params,
    })

    return { type: 'ApiGET_EVENT_ENGINEER_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiGET_EVENT_ENGINEER_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiGet_vw_field_activity = (filter = { limit: 200, skip: 0 }) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: GET_vw_field_activity.REQUEST })

    const headers = {
      Authorization: null,
      'Content-Type': 'application/json',
    }
    let params = {
      '$limit': filter.limit,
      '$skip': filter.skip,
    }

    if(filter.activity_type_no){
      params = {...params,'activity_type_no': filter.activity_type_no}
    }

    const data_result = await getAPI({
      url: '/api/mas/vw_field_activity?$sort[activity_type_no]=1',
      headers: headers,
      params,
    })

    return { type: 'ApiGet_vw_field_activity_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiGet_vw_field_activity_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiGet_vw_criteria_activity = (filter = { limit: 200, skip: 0 }) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: GET_vw_criteria_activity.REQUEST })

    const headers = {
      Authorization: null,
      'Content-Type': 'application/json',
    }
    let params = {
      '$limit': filter.limit,
      '$skip': filter.skip,
    }

    if(filter.activity_number){
      params = {...params,'activity_number': filter.activity_number}
    }

    const data_result = await getAPI({
      url: '/api/mas/vw_criteria_activity?$sort[id]=1',
      headers: headers,
      params,
    })

    return { type: 'ApiGet_vw_criteria_activity_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiGet_vw_criteria_activity_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiGetCpdActivityCriteria = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_MASTER.REQUEST })
    const headers = {
      Authorization: null,
      'Content-Type': 'application/json',
    }
    
    const data_result = await getAPI({
      url: `/api/mas/GetCpdActivityCriteria/${id}`,
      headers: headers,
    })

    return { type: 'ApiGetCpdActivityCriteria_SUCCESS', data_info: data_result }

  } catch (err) {

    return { type: 'ApiGetCpdActivityCriteria_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiGetMemberORG = (filter = { limit: 200, skip: 0 }) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: GET_MEMBER_ORG.REQUEST })

    const headers = {
      Authorization: null,
      'Content-Type': 'application/json',
    }
    let params = {
      '$limit': filter.limit,
      '$skip': filter.skip,
    }

    if(filter.not_coe){
      params = {...params,'id[$ne]': 94}
    }

    const data_result = await getAPI({
      url: '/api/mas/vw_member_organization?$sort[id]=1',
      headers: headers,
      params,
    })

    return { type: 'ApiGetMemberORG_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiGetMemberORG_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

